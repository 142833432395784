body {
  margin: 0;
  font-family: 'Ubuntu', sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: 'Ubuntu', sans-serif!important; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6, button, a {
  font-family: 'Baloo Paaji 2', cursive!important;
  font-weight: bold!important;
}